:root{
  --background: #ffffff;
  --font-color: #000000;
  --reverse-font-color: #ffffff;
  --unemphasized-font: #a0a7b1;
  --border: #DEE2E1;
  --shadow: rgba(0, 0, 0, 0.3);
  --even-row: #ffffff;
  --odd-row: #f6f9ff; 
  --hover: #e2ecff;
  --selected: #928C9F;
  --button-radius: 0.7rem;
}

[data-dark-mode] {
  --background: #000000;
  --font-color: #ffffff;
  --reverse-font-color: #000000;
  --unemphasized-font: #a0a7b1;
}

@media (prefers-color-scheme: dark) {
  --background: #000000;
  --font-color: #ffffff;
  --reverse-font-color: #000000;
  --unemphasized-font: #a0a7b1;
}

body {
  font-family: sans-serif;
  color: var(--font-color);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background);
  flex-direction: column;
  
}

/* .area{ */
/* background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%); */
  /* background-image: linear-gradient(to top, #fff 10px, transparent 2px), linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%); */
  /* background-image: linear-gradient(to top, #fff 10px, transparent 2px),linear-gradient(19deg, #fe5301, #ffe0d0); */
/* } */

.container {
  display: flex;
  flex-direction: column;
  position:relative;
  align-items: center;
  
}

.message {
  text-align: center;
  width: 70%;
}

.plot.wrapper {
  position: relative;
}

/* Add black lines */
.plot.wrapper::before,
.plot.wrapper::after {
  content: '';
  position: absolute;
  background: var(--border);
}

/* Vertical line */
.plot.wrapper::before {
  top: 0;
  bottom: 0;
  width: 1px; /* Adjust thickness of the line */
  left: 50%; /* Position at the center horizontally */
  transform: translateX(-50%); /* Adjust for the thickness of the line */
}

/* Horizontal line */
.plot.wrapper::after {
  left: 0;
  right: 0;
  height: 1px; /* Adjust thickness of the line */
  top: 50%; /* Position at the center vertically */
  transform: translateY(-50%); /* Adjust for the thickness of the line */
}

.row {
  display: flex;
}

.circle{
  width: 80px;
  height: 120px;
  border-radius: 10px;
  border: 1px solid var(--border);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

/* .circle{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid var(--border);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
} */

.design{
  fill: white;
  margin-top: -10em;
  width: 100%;
}

.increase-active {
  background: #075F49;
  color: var(--reverse-font-color);
}

.increase-passive {
  background: #7EDEC6;
}

.decrease-active {
  background: #8C2242;
  color: var(--reverse-font-color);
}

.decrease-passive {
  background: #FFBDD1;
}

.circle.selected {
  background: var(--selected);
  color: var(--reverse-font-color);
  
}

.circle:hover {
  /* box-shadow: 0px 4px 12px var(--shadow); */
  border: 1px solid var(--selected);

}

/* Styles for the items list */
.table{
  border-radius: 1rem;
  box-shadow: 0px 4px 12px var(--shadow);
  padding: 1.5rem;
  border: 1px solid var(--border);
  width: 90%;
  /* max-width:fit-content; */
  overflow-x: auto;
}
.table caption {
  font-size: 1.12rem;
  font-weight:700;
  text-align: left;
  margin: 0.5rem 0px;
  text-wrap: nowrap;

}
.resulting-table {
  max-width: fit-content;
  border-collapse: collapse;
  
}

.resulting-table th {
  font-size: 0.875rem; 
  color: var(--unemphasized-font);
  padding: 1.25rem 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  background: var(--even-row)
  
  
}

.resulting-table td {
  padding: 0.5rem 1rem;
  text-align: left;
  vertical-align: middle;
}

.resulting-table tr {
  cursor: pointer;
}

.resulting-table tr:hover {
  background: var(--hover);
}


tr:nth-child(odd) {
  background: var(--odd-row);
}
/* tr:nth-child(even) {
  background: var(--even-row);
} */

.spacer.label.top {
  width: 33%;
  flex-grow: 1;
  margin-bottom: 10px;
  color: transparent;
}

.spacer.label.bottom {
  flex-grow: 1;
  width: 100%;
  margin-top: 10px;
  color: transparent;
}
.spacer.label.left {
  flex-grow: 1;
  height: 50%;
  margin-right: 10px;
  width:100px;
  color: transparent;
}

.spacer.title {
  font-size: 20px; 
  font-weight: bold; 
  padding: 10px; 
  color: transparent;
}

.axis {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.axis.title {
  font-size: 20px; 
  font-weight: bold; 
  padding: 10px; 
}

.axis.labels {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.axis.labels.horizontal {
  display: flex;
  flex-direction: row;
  width: 100%; 
  flex-grow: 1;
}

.axis.labels.vertical {
  display: flex;
  flex-direction: column;
  width: 100%; 
  flex-grow: 1;
  
}

.axis.label {
  font-size: 18px;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: var(--button-radius);
}

.axis.label:hover {
  text-decoration: underline;
}

.axis.label.selected {
  /* font-weight: bold; */
  background: var(--selected);
  color: var(--reverse-font-color);
  
}

.axis.label.top {
  width: 33%;
  flex-grow: 1;
  margin-bottom: 10px;
}

.axis.label.bottom {
  flex-grow: 1;
  width: 100%;
  margin-top: 10px;
}

.axis.label.left {
  flex-grow: 1;
  height: 50%;
  margin-right: 10px;
  width:100px;
}

.behavior {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  border-radius: var(--button-radius);
  border: 1px solid var(--border);
  cursor: pointer;
}

.behavior.selected {
  background: var(--selected);
  color: var(--reverse-font-color);
  /* box-shadow: 0px 4px 12px var(--shadow); */
}

.behavior:hover {
  /* box-shadow: 0px 4px 12px var(--shadow); */
  border: 1px solid var(--selected);
}
